.wrapper {
    padding: 10px;
    height: 100vh;
}

.gold {
    height: 100vh;
    padding: 10px;
    background-color: #f8d86f;
    color: white !important;
}
.avatar {
    border-radius: 10px;
    width: 300px;
    height: auto;

    @media (max-width: 600px) {
        width: 190px;
        height: auto;
        border-radius: 5px;
    }
}
