.error {
    border: 1px solid rgb(231, 91, 91);
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    @media (max-width: 600px) {
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 5px;
    }
}
