.gradient {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: linear-gradient(to right, red, orange);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}
