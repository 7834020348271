.Button {
    cursor: pointer;
    color: #141c1f;
    padding: 1px 5px;
    display: flex;
    align-items: center;

    &:hover {
        color: #00c8ff;

        .addonLeft svg,
        .addonRight svg {
            color: #00c8ff;
        }
    }
}

.withAddon {
    padding: 0 16px;
}

.addonLeft,
.addonRight {
    display: flex;
}

.addonRight svg,
.addonLeft svg {
    color: #adbcc0;
}

.clear {
    padding: 0;
    border: none;
    background: none;
    outline: none;
}

.filled {
    background: #e2eef1;

    outline: none;
    border-radius: 10px;
    &:hover {
        border: 1px solid #00c8ff;
    }

    &.normal {
        border: 1px solid #5ed3f3;
    }

    &.error {
        border: 1px solid #ff7777;
    }

    &.success {
        border: 1px solid #62de85;
    }
}

.outline {
    background: none;
    border-radius: 3px;

    &:hover {
        border: 1px solid #00c8ff;
    }

    &.normal {
        border: 1px solid #5ed3f3;
    }

    &.error {
        border: 1px solid #ff7777;
    }

    &.success {
        border: 1px solid #62de85;
    }
}

.square {
    padding: 0;
}

.square.m {
    width: 20px;
    height: 20px;
}

.square.l {
    width: 32px;
    height: 32px;
}

.square.xl {
    width: 32px;
    height: 32px;
}

.s {
    font-size: 10px;
}
.m {
    font: var(--font-m-redesigned);
}

.l {
    font: var(--font-l-redesigned);
}

.xl {
    font: var(--font-xl-redesigned);
}

.disabled {
    opacity: 0.5;
}

.fullWidth {
    width: 100%;
}
