.avatar {
    border-radius: 10px;
    width: 300px;
    height: auto;

    @media (max-width: 600px) {
        width: 190px;
        height: auto;
        border-radius: 5px;
    }
}

.avatarSkeleton {
    border-radius: 10px;
    width: 300px !important;
    height: 300px !important;
    @media (max-width: 600px) {
        width: 190px !important;
        height: auto;
        border-radius: 5px;
    }
}
