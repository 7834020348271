@font-face {
    font-family: 'SFProDisplay';
    src: url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

:root {
    --font-family-main: 'SFProDisplay';

    // s
    --font-size-s: 12px;
    --font-line-s: 20px;
    --font-s: var(--font-size-s) / var(--font-line-s) var(--font-family-main);

    // m
    --font-size-m: 16px;
    --font-line-m: 24px;
    --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);

    // l
    --font-size-l: 24px;
    --font-line-l: 32px;
    --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);

    // xl
    --font-size-xl: 32px;
    --font-line-xl: 40px;
    --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);

    // Размеры
    --navbar-height: 50px;
    --sidebar-width: 300px;
    --sidebar-width-collapsed: 80px;

    // z - Индексы
    --modal-z-index: 1000;
    --overlay-z-index: 100;

    // Цвета
    --overlay-color: rgba(0 0 0 / 60%);
    --red-light: #f00;
    --red-dark: #ce0505;
}
