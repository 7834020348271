.gold {
    background-color: rgb(248, 216, 111);
    a {
        color: rgb(29, 28, 28);
        font-weight: 600;
    }
}

.link {
    width: 100%;
    display: block;
}
.owner {
    float: right;
    font-size: 12px;
    color: rgb(163, 163, 163);
}

.gray {
    border: 1px solid red !important;
    filter: grayscale(50%);
}

.table_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mbLink {
    cursor: pointer;
    text-decoration: underline;
    color: blue;
}
