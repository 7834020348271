@import 'reset';
@import 'variables/global';
@import 'themes/normal';
@import 'themes/dark';

.app,
.app_modal,
.app_drawer {
    font-family: 'SFProDisplay';
    font: var(--font-m);
    color: var(--primary-color);
}

.app {
    background: var(--bg-color);
    min-height: 100vh;
}

.content-page {
    display: flex;
}

.page-wrapper {
    margin-top: 10px;
}
body {
    font-family: 'SFProDisplay';
    background-image: url('../../shared/assets/images/wakk.jpg');
    background-attachment: fixed;
}

.Typewriter__cursor {
    display: none;
}
